import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { Element } from "react-scroll";

import ComponentRenderer from "ComponentRenderer.js";
import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
import Story from "components/features/TwoColSingleFeatureWithStats2.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import BlogIndex from "pages/BlogIndex";
import AboutUs from "pages/AboutUs";
import Contact from "pages/ContactUs";

export default function App() {
  // return <AnimationRevealPage disabled></AnimationRevealPage>;
  return (
    <Router>
      <Switch>
        <Route path="/blog" component={BlogIndex} />
        <Route path="/more-about-us" component={AboutUs} />
        <Route path="/contact" component={Contact} />
        <Route path="/" component={SaaSProductLandingPage} />
      </Switch>
    </Router>
  );
}
