import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/headerBlog.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import BrainstormingPhoto from "images/brainstorming.jpg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import GroupPhoto from "images/groupPhoto.jpg";

const Blue = tw.span`text-flag-1`;
const Yellow = tw.span`text-flag-2`;
const Red = tw.span`text-flag-3`;

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About RSA</Subheading>}
        heading={
          <>
            We are the <Blue>Romanian</Blue> <Yellow>Student</Yellow>{" "}
            <Red>Association</Red>
          </>
        }
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc={GroupPhoto}
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to become the organization that anybody can count on."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc={BrainstormingPhoto}
        textOnLeft={false}
      />

      <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} />
      <Footer />
    </AnimationRevealPage>
  );
};
